import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MaterialButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGridPro, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from "react-bootstrap";
import { useConnectEnvironmentMutation } from "../../backend/hooks/interactive/queryConnectEnvironment";
import { useUpdateEnvironmentSettingsMutation } from "../../backend/hooks/interactive/mutationUpdateEnvironmentSettings";
import { useUpdateEnvironmentStatusMutation } from "../../backend/hooks/interactive/mutationUpdateEnvironmentStatus";
import { useQueryEnvironmentsQuery } from "../../backend/hooks/interactive/queryListEnvironments";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RefreshIcon from "@mui/icons-material/Refresh";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link, useHistory } from "react-router-dom";
import { useGetHistoryLabels } from "../../backend/hooks/history/queryGetHistoryLabels";
import { useListInstanceTypesQuery } from "../../backend/hooks/interactive/queryListInstanceTypes";
import {
  Environment,
  EnvironmentState,
  EnvironmentStatus,
  InstanceType,
  Software
} from "../../backend/interactiveTypes";
import { BrowsePmxActivityContext } from "../../contexts/browsePmxActivityProvider";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import {
  INTERACTIVE_ALERT_WARNING,
  formatStringToDateTime,
  priceCalculationPerHour
} from "../../helpers/stringHelper";
import { useUserHelper } from "../../hooks/userHelper";
import Error from "../abstractComponents/error";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import Success from "../abstractComponents/success";
import { useAuth } from "../authContextProvider";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import { CreateInteractiveModal } from "./createInteractiveModal";

// Component used for creating and deleting of interactive environments
// and for transfering data and receiving informations concerning the interacitve environment
export function InteractiveList() {
  const { token } = useAuth();
  const { activityId, activityData, isActivityLoading, errorActivity } =
    useContext(PmxActivityDetailsContext);
  const { refetchRepoObjects, isLoadingGetRepoObjects, repos } = useContext(
    BrowsePmxActivityContext
  );
  const [currentSubArray, setCurrentSubarray] = useState<any[]>([]);
  const { isBayerUser } = useUserHelper();
  const { treeHierarchyGlobal } = useContext(BrowsePmxActivityContext);

  const { historyLabels, getHistoryLabelsLoading, getHistoryLabelsError } =
    useGetHistoryLabels(Number(activityId));

  useEffect(() => {
    refetchRepoObjects();
  }, []);
  const history = useHistory();

  const COLUMNS = [
    {
      field: "environmentName",
      headerName: "Environment Name",
      flex: 2,
      renderCell: (params: any) => (
        <div key={params.row.environmentId} className="full_100_width">
          <div className="button_without_text_transform float-start">
            <Link
              className="interactive_link"
              to={`/activity/${activityId}/interactive/${params.row.environmentId}`}
            >
              <CloudDoneOutlinedIcon /> {params.row.environmentName}
            </Link>
          </div>
        </div>
      )
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params: any) => (
        <>
          {params.row.image.imageName}{" "}
          {params.row.image.software &&
            params.row.image.software.length > 0 && (
              <OverlayTrigger
                placement="right"
                overlay={(props) => (
                  <Tooltip {...props} id="info-image-tooltip">
                    {params.row.image.software.map((software: Software) => (
                      <>
                        {software.name} - {software.version} <br />
                      </>
                    ))}
                  </Tooltip>
                )}
              >
                <Badge className="ineractive_info_badge" bg="info">
                  Info
                </Badge>
              </OverlayTrigger>
            )}
        </>
      )
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      renderCell: (params: any) => {
        let displayState = params.row.state;

        if (
          params.row.state === EnvironmentState.Running &&
          (params.row.instanceStatus !== EnvironmentStatus.Ok ||
            params.row.systemStatus !== EnvironmentStatus.Ok)
        ) {
          displayState = EnvironmentState.Pending;
        }

        if (
          params.row.instanceStatus === EnvironmentStatus.InsufficientData ||
          params.row.instanceStatus === EnvironmentStatus.Impaired ||
          params.row.systemStatus === EnvironmentStatus.InsufficientData ||
          params.row.systemStatus === EnvironmentStatus.Impaired
        ) {
          displayState = "error";
        }

        return (
          <div
            key={params.row.environmentId + "status"}
            className="full_100_width"
          >
            <div
              className="float-start center_span_table"
              title={`instanceStatus:${params.row.instanceStatus}, systemStatus:${params.row.systemStatus}`}
            >
              {displayState}
            </div>
            {(params.row.state === EnvironmentState.Running ||
              params.row.state === EnvironmentState.Stopped ||
              displayState === "error") && (
              <MaterialButton
                variant="text"
                className="float-end inner_menu_button"
                onClick={(e) => handleClickStatus(e, params.row)}
              >
                <MoreVertIcon className="dot_menu_change_status grey_color" />
              </MaterialButton>
            )}
          </div>
        );
      }
    },
    {
      field: "instanceType",
      headerName: "Instance Type",
      flex: 1,
      renderCell: (params: any) => (
        <div
          key={params.row.environmentId + "instanceType"}
          className="full_100_width"
          title="Server must be stopped for this to change"
        >
          <div className="float-start center_span_table">
            {params.row.instanceType.instanceTypeId}
          </div>
          {(params.row.state === EnvironmentState.Running ||
            params.row.state === EnvironmentState.Stopped) && (
            <MaterialButton
              variant="text"
              className="float-end inner_menu_button"
              onClick={(e) => handleClickInstanceType(e, params.row)}
              disabled={params.row.state !== EnvironmentState.Stopped}
            >
              <MoreVertIcon className="dot_menu_change_status grey_color" />
            </MaterialButton>
          )}
        </div>
      )
    },
    {
      field: "privateIP", // reuse another column
      flex: 1,
      headerName: "Price",
      valueGetter: (params: GridValueGetterParams) =>
        `€${Math.round(
          priceCalculationPerHour(params.row.instanceType.instanceTypeId) * 24
        )}/Day`
    },
    {
      field: "instanceId", // reuse another column
      headerName: "Connection",
      flex: 1.2,
      renderCell: (params: any) => (
        <div className="d-flex gap-2 justify-content-end">
          <Button
            key={params.row.environmentId + "Connection"}
            onClick={() => {
              connectToEnvironment(params.row.environmentId);
            }}
            size="sm"
            className="connect_to_interactive"
            disabled={
              params.row.instanceStatus !== EnvironmentStatus.Ok ||
              params.row.systemStatus !== EnvironmentStatus.Ok ||
              params.row.state !== EnvironmentState.Running
            }
          >
            Connect
          </Button>
          <Button
            id="run-execution-job-button"
            size="sm"
            onClick={() => {
              history.push(
                `/activity/${activityData.id}/interactive/${params.row.environmentId}/IEPlugin`
              );
            }}
          >
            Run Job
          </Button>
        </div>
      )
    },
    { field: "createdBy", flex: 1, headerName: "Created By" },
    {
      field: "createdAt",
      flex: 1,
      headerName: "Created At",
      valueGetter: (params: GridValueGetterParams) =>
        formatStringToDateTime(params.row.createdAt)
    },
    {
      field: "actionId",
      headerName: "History Label",
      flex: 1,
      renderCell: (params: any) => {
        let historyLabel = historyLabels?.historyLabels.find(
          (historyLabel: any) => historyLabel.actionId === params.row.actionId
        );

        return (
          <div key={params.row.actionId} className="full_100_width">
            <div className="float-start">
              <Link
                className="ActionId"
                to={`/activity/${activityId}/history/fileExplorer?actionId=${params.row.actionId}`}
              >
                {historyLabel ? historyLabel.name : params.row.actionId}
              </Link>
            </div>
          </div>
        );
      }
    }
  ];

  const [currentEnvironment, setCurrentEnvironment] =
    useState<null | Environment>(null);
  const [anchorElementStatus, setAnchorElementStatus] =
    useState<null | HTMLElement>(null);

  const [anchorElementInstanceType, setAnchorElementInstanceType] =
    useState<null | HTMLElement>(null);
  const [isImportAllFiles, setIsImportAllFiles] = useState<boolean>(true);
  const [selectedFileImport, setSelectedFileImport] = useState<string[]>([]);

  const {
    environments,
    isLoadingEnvironments,
    errorListEnvironments,
    refetchEnvironments
  } = useQueryEnvironmentsQuery(activityId);

  const { connectEnvironment, isLoadingConnectionUrl, errorConnectionUrl } =
    useConnectEnvironmentMutation();

  const {
    updateEnvironmentStatus,
    isLoadingUpdateEnvironmentStatus,
    errorLoadingUpdateEnvironmentStatus,
    updateEnvironmentStatusData
  } = useUpdateEnvironmentStatusMutation();

  const {
    updateEnvironmentSettings,
    isLoadingEnvironmentSettings,
    errorEnvironmentSettings,
    updateEnvironmentSettingsData
  } = useUpdateEnvironmentSettingsMutation();

  const {
    environmentTypes,
    isLoadingEnvironmentTypes,
    errorListEnvironmentTypes
  } = useListInstanceTypesQuery();

  const connectToEnvironment = (environmentId: String) => {
    connectEnvironment({ variables: { environmentId: environmentId } }).then(
      ({ data }) => {
        window.open(data.connectEnvironment.url, "_blank")?.focus();
      }
    );
  };

  const handleClickStatus = (
    event: React.MouseEvent<HTMLButtonElement>,
    environment: Environment
  ) => {
    setAnchorElementStatus(event.currentTarget);
    setCurrentEnvironment(environment);
  };

  const handleClickInstanceType = (
    event: React.MouseEvent<HTMLButtonElement>,
    environment: Environment
  ) => {
    setAnchorElementInstanceType(event.currentTarget);
    setCurrentEnvironment(environment);
  };

  const updateEnvStatus = (state: string) => {
    setAnchorElementStatus(null);
    if (
      state !== "delete" ||
      (state === "delete" && window.confirm(INTERACTIVE_ALERT_WARNING))
    ) {
      updateEnvironmentStatus({
        variables: {
          environmentId: currentEnvironment?.environmentId,
          environmentStatusChange: state
        }
      }).then(() => {
        refetchEnvironments();
      });
    }
  };

  const updateEnvType = (type: string) => {
    setAnchorElementInstanceType(null);
    updateEnvironmentSettings({
      variables: {
        environmentId: currentEnvironment?.environmentId,
        instanceTypeId: type
      }
    }).then(() => {
      refetchEnvironments();
    });
  };

  // modal steps
  const steps = [
    "Interactive Environment Settings",
    "Choose files to import",
    "Summary"
  ];

  useEffect(() => {
    const currentArray: any[] = [];
    repos.map((repo: any) =>
      repo.repoObjects.map((file: any) =>
        currentArray.push({
          repoName: repo.repo.name,
          name: `${repo.repo.name}/${file.name}`,
          isDir: file.isDir,
          createdAt: file.created,
          createdBy: file.createdBy,
          isValid: file.isValid,
          versionId: file.versionId
        })
      )
    );

    setCurrentSubarray(currentArray);
  }, [repos]);

  useEffect(() => {
    if (isImportAllFiles) {
      const rows: any = [];
      currentSubArray.map((file) => rows.push(file.name));

      setSelectedFileImport(rows);
    }
  }, [isImportAllFiles, currentSubArray]);

  return (
    <>
      <NavBarPmxActivity activeIndex={1} />
      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          ,<Typography>Interactive Environments</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        {isBayerUser && activityData && (
          <ErrorBoundary>
            <Container fluid>
              <Row>
                <Col xs={7}>
                  {(isLoadingEnvironments ||
                    isLoadingUpdateEnvironmentStatus ||
                    isLoadingEnvironmentTypes ||
                    isActivityLoading ||
                    isLoadingEnvironmentSettings ||
                    isLoadingConnectionUrl ||
                    getHistoryLabelsLoading ||
                    isLoadingGetRepoObjects) && (
                    <div className="center_div">
                      <Spinner animation="border" className="spinner_color" />
                      <p>Loading...</p>
                    </div>
                  )}
                </Col>
                <Col>
                  <Button
                    onClick={() => refetchEnvironments()}
                    id="refresh_list_of_env"
                    variant="outline-secondary"
                    className="float-end"
                  >
                    <RefreshIcon /> Refresh
                  </Button>
                </Col>
                <Col>
                  <CreateInteractiveModal
                    repos={repos}
                    tree={treeHierarchyGlobal}
                    disabeld={isLoadingGetRepoObjects}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <DataGridPro
                  rows={environments || []}
                  columns={COLUMNS}
                  rowHeight={60}
                  pageSize={10}
                  getRowId={(row) => row.environmentId}
                  autoHeight
                  disableSelectionOnClick
                  className="choose_interactive_list"
                />
              </Row>

              <br />
            </Container>

            <Menu
              id="simple-menu-status"
              anchorEl={anchorElementStatus}
              open={Boolean(anchorElementStatus)}
              onClose={() => {
                setAnchorElementStatus(null);
              }}
            >
              <MenuItem
                id="start_status"
                onClick={() => {
                  updateEnvStatus("start");
                }}
                disabled={
                  currentEnvironment?.state === EnvironmentState.Running
                }
              >
                Start
              </MenuItem>
              <MenuItem
                id="stop_status"
                onClick={() => {
                  updateEnvStatus("stop");
                }}
                disabled={
                  currentEnvironment?.state === EnvironmentState.Stopped
                }
              >
                Stop
              </MenuItem>
              <MenuItem
                id="delete_status"
                onClick={() => {
                  updateEnvStatus("delete");
                }}
              >
                Delete
              </MenuItem>
            </Menu>

            <Menu
              id="simple-menu-status"
              anchorEl={anchorElementInstanceType}
              open={Boolean(anchorElementInstanceType)}
              onClose={() => {
                setAnchorElementInstanceType(null);
              }}
            >
              {environmentTypes.map((instanceType: InstanceType) => (
                <MenuItem
                  key={instanceType.instanceTypeId}
                  id={"instance_type_" + instanceType.instanceTypeId}
                  onClick={() => {
                    updateEnvType(instanceType.instanceTypeId);
                  }}
                >
                  <Typography
                    sx={{ width: "200px", fontWeight: "bold" }}
                    variant="button"
                    display="block"
                  >
                    {instanceType.instanceTypeId}
                  </Typography>
                  <Typography sx={{ width: "140px" }}>
                    {instanceType.cpu}xCPU
                  </Typography>
                  <Typography sx={{ width: "150px" }}>
                    {instanceType.memory}GB RAM
                  </Typography>
                  <Typography sx={{ width: "140px" }}>
                    €
                    {Math.round(
                      priceCalculationPerHour(instanceType.instanceTypeId) * 24
                    )}
                    /Day
                  </Typography>
                </MenuItem>
              ))}
            </Menu>

            {(errorListEnvironments ||
              errorLoadingUpdateEnvironmentStatus ||
              errorListEnvironmentTypes ||
              errorActivity ||
              errorEnvironmentSettings ||
              getHistoryLabelsError ||
              errorConnectionUrl) && (
              <Error
                error={
                  errorListEnvironments ||
                  errorLoadingUpdateEnvironmentStatus ||
                  errorListEnvironmentTypes ||
                  errorActivity ||
                  errorEnvironmentSettings ||
                  getHistoryLabelsError ||
                  errorConnectionUrl
                }
              />
            )}

            {(updateEnvironmentStatusData || updateEnvironmentSettingsData) && (
              <Success message="The update will take up to one minute" />
            )}
          </ErrorBoundary>
        )}
      </div>
    </>
  );
}

export default InteractiveList;
