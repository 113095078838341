import { gql, useLazyQuery } from "@apollo/client";
export const ExecutionInputRecommendations = gql`
  query getExecutionInputRecommendations(
    $activityId: ID!
    $entrypoint: RepoObjectInput!
  ) {
    getInputRecommendations(
      input: { activityId: $activityId, entrypoint: $entrypoint }
    ) {
      inputs
    }
  }
`;

export function useQueryGetExecInputRecommendations() {
  const [
    getExecutionInputRecommendations,
    {
      data: executionInputRecommendations,
      loading: isLoadingExecutionInputRecommendations,
      error: errrorExecutionInputRecommendations
    }
  ] = useLazyQuery(ExecutionInputRecommendations, {
    fetchPolicy: "no-cache",
    context: { clientName: "execution" }
  });
  return {
    getExecutionInputRecommendations,
    execInputRecommendations: executionInputRecommendations?.input,
    isLoadingExecutionInputRecommendations,
    errrorExecutionInputRecommendations
  };
}
