import { gql, useLazyQuery } from "@apollo/client";
export const ExecutionImageRecommendations = gql`
  query getExecutionImageRecommendations(
    $environmentId: ID!
    $repoName: String!
    $path: String!
  ) {
    getExecutionImageRecommendations(
      input: { environmentId: $environmentId, repoName: $repoName, path: $path }
    ) {
      images
    }
  }
`;

export function useQueryGetExecIEImageRecommendations() {
  const [
    getExecIEImageRecommendations,
    {
      data: executionImageRecommendations,
      loading: isLoadingExecIEImageRecommendations,
      error: errrorExecIEImageRecommendations
    }
  ] = useLazyQuery(ExecutionImageRecommendations, {
    fetchPolicy: "no-cache",
    context: { clientName: "interactive" }
  });
  return {
    getExecIEImageRecommendations,
    execIEImageRecommendations:
      executionImageRecommendations?.getExecutionImageRecommendations.images,
    isLoadingExecIEImageRecommendations,
    errrorExecIEImageRecommendations
  };
}
