import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Breadcrumbs, Tab, Tabs, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useCancelExeuctionJobMutation } from "../../backend/hooks/execution/mutationCancelJob";
import { useGetJob } from "../../backend/hooks/execution/queryGetJob";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { useUserHelper } from "../../hooks/userHelper";
import Error from "../abstractComponents/error";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import { ExecutionGeneral } from "./executionGeneral";
import { ExecutionLogs } from "./executionLogs";
import { ExecutionActionDetails } from "./executionActionDetails";
import { ExecutionImageDetails } from "./executionImage";

export function ExecutionInfo() {
  const { activityData } = useContext(PmxActivityDetailsContext);
  const { activityId, executionId } = useParams<{
    activityId: string;
    executionId: string;
  }>();
  const history = useHistory();
  const { isBayerUser } = useUserHelper();
  const [tabValue, setTabValue] = useState<number>(0);
  const showImage: { showImage: boolean } = useLocation().state as {
    showImage: boolean;
  };
  const { job, isGetJobLoading, errorGetJob, refetchJob } =
    useGetJob(executionId);

  const { cancelJob, isLoadingCancelJob, errorCancelJob } =
    useCancelExeuctionJobMutation();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onCancelJob = () => {
    // Todo: support other environment checks
    const variables = {
      jobId: job.id
    };

    if (
      job?.status === "RUNNING" &&
      window.confirm("Are you sure that you want to cancel this job?")
    ) {
      cancelJob({
        variables
      }).then(() => {
        history.push(`/activity/${activityId}/executions`, {
          refreshJobs: true
        });
      });
    }
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabContent(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  useEffect(() => {
    if (job?.activityId && job?.activityId !== activityId) {
      history.push(`/activity/${activityId}`);
      if (!job.activityId) {
        history.push(`/activity/${activityId}`);
      }
    }
  }, [job]);

  useEffect(() => {
    showImage?.showImage && setTabValue(2);
  }, []);

  return (
    <>
      <NavBarPmxActivity activeIndex={2} />
      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          <Link to={`/activity/${activityId}/executions`}>Execution Jobs</Link>
          <Typography>{job?.name}</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        <ErrorBoundary>
          <Container fluid className="modal-75w">
            {!isBayerUser || isGetJobLoading ? (
              <>
                <Row>
                  <div className="center_div">
                    <Spinner animation="border" className="spinner_color" />
                    <p>Loading...</p>
                  </div>
                </Row>
              </>
            ) : (
              <Row>
                <Col className="d-flex justify-content-end gap-2">
                  <Button
                    onClick={() => refetchJob()}
                    id="refresh_list_of_jobs"
                    variant="outline-secondary"
                  >
                    <RefreshIcon /> Refresh
                  </Button>
                  <Link
                    to={`/activity/${activityId}/executions/new?jobId=${job?.id}`}
                  >
                    <Button id="clone-job" variant="primary">
                      Clone Job
                    </Button>
                  </Link>
                  <Button
                    id="cancel_job"
                    variant="danger"
                    disabled={job?.status !== "RUNNING" || isLoadingCancelJob}
                    onClick={onCancelJob}
                  >
                    Cancel Job
                  </Button>
                </Col>
              </Row>
            )}

            {!isGetJobLoading && (
              <>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="execution job tabs"
                >
                  <Tab label="General" {...a11yProps(0)} />
                  <Tab label="Action" {...a11yProps(1)} />
                  <Tab label="Image" {...a11yProps(2)} />
                  <Tab label="Logs" {...a11yProps(3)} />
                </Tabs>
                <TabContent value={tabValue} index={0}>
                  <ExecutionGeneral job={job} />
                </TabContent>
                <TabContent value={tabValue} index={1}>
                  <ExecutionActionDetails job={job} />
                </TabContent>
                <TabContent value={tabValue} index={2}>
                  <ExecutionImageDetails images={[job?.image]} />
                </TabContent>
                <TabContent value={tabValue} index={3}>
                  <ExecutionLogs job={job} />
                </TabContent>
              </>
            )}
          </Container>
        </ErrorBoundary>
      </div>
      {(errorGetJob || errorCancelJob) && (
        <Error error={errorGetJob || errorCancelJob} />
      )}
    </>
  );
}
