import { InteractiveFile } from "../../backend/interactiveTypes";
import { GetRepoObjectQueryData, PmxActivity } from "../../backend/types";
import getTreeHierarchyFromRepoObjectList from "../../helpers/treeHelper";

const getAllRepos = (activityData: PmxActivity) => {
  let repos = [
    {
      name: activityData?.mainRepository.name,
      id: activityData?.mainRepository.id,
      isReadOnlyRepository: false
    }
  ];
  if (
    activityData?.readOnlyRepositories &&
    activityData.readOnlyRepositories.length > 0
  ) {
    activityData.readOnlyRepositories.forEach((repo) => {
      repos.push({
        name: repo?.name ?? "",
        id: repo?.id ?? "",
        isReadOnlyRepository: true
      });
    });
  }
  return repos;
};

export const castingFilesToRepObjectTree = (
  interactiveFiles: InteractiveFile[],
  activityData: PmxActivity
) => {
  let updateObject: GetRepoObjectQueryData[] = [];
  const repos = getAllRepos(activityData);
  const repoObjectsMap: any = {};
  const folderStructure: any = [];

  interactiveFiles?.forEach((file: InteractiveFile) => {
    if (!repoObjectsMap[file.repoName]) {
      repoObjectsMap[file.repoName] = [];
    }
    repoObjectsMap[file.repoName].push({
      name: file.path,
      isDir: false
    });

    const subFolders = file.path.split("/");
    subFolders.pop(); // Remove the file name from the array
    let key = "";
    subFolders.forEach((sub) => {
      key += sub + "/";
      if (!folderStructure.includes(key)) {
        repoObjectsMap[file.repoName].push({
          name: key,
          isDir: true
        });
        folderStructure.push(key);
      }
    });
  });
  Object.keys(repoObjectsMap).forEach((repoName) => {
    updateObject.push({
      repo: {
        name: repoName,
        id: repos.find((repo) => repo.name === repoName)?.id
      },
      repoObjects: repoObjectsMap[repoName],
      isReadOnlyRepository: repos.find((repo) => repo.name === repoName)
        ?.isReadOnlyRepository
    } as unknown as GetRepoObjectQueryData);
  });
  return getTreeHierarchyFromRepoObjectList(updateObject);
};
