import { useContext, useEffect, useMemo } from "react";
import { useSubmitExecutionJobMutation } from "../../../backend/hooks/execution/mutationSubmitJob";
import { useListExecutionImagesQuery } from "../../../backend/hooks/execution/queryGetImages";
import { useSubmitExecutionJobFromInteractiveMutation } from "../../../backend/hooks/interactive/mutationSubmitJob";
import { useQueryGetExecIEImageRecommendations } from "../../../backend/hooks/interactive/queryGetExecImageIERecommendations";
import { useQueryGetExecInputIERecommendations } from "../../../backend/hooks/interactive/queryGetExecInputIERecommendations";
import { PmxActivityDetailsContext } from "../../../contexts/pmxActivityDetailsProvider";
import { useHistoryVersionsLazyQuery } from "../../../backend/hooks/contextMenu/queryGetHistoryVersions";
import { useQueryGetExecImageRecommendations } from "../../../backend/hooks/execution/queryGetExecImageRecommendations";
import { useQueryGetExecInputRecommendations } from "../../../backend/hooks/execution/queryGetExecInputRecommendations";

// all queries and mutations related to creating job for execution environment
export function useCreateJobHook() {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);

  const {
    getFileVersions,
    isLoading: getFileVersionsLoading,
    error: getFileVersionsError
  } = useHistoryVersionsLazyQuery();

  const {
    executionImages,
    isLoadingGetExecutionImages,
    errorGetExecutionImages
  } = useListExecutionImagesQuery();

  // interactive
  const {
    submitJobFromIE,
    isSubmitExecutionJobIELoading,
    errorSubmitExecutionJobIE
  } = useSubmitExecutionJobFromInteractiveMutation();

  const {
    getExecIEImageRecommendations,
    isLoadingExecIEImageRecommendations,
    errrorExecIEImageRecommendations
  } = useQueryGetExecIEImageRecommendations();

  const {
    getExecInputIERecommendations,
    isLoadingExecInputIERecommendations,
    errrorExecInputIERecommendations
  } = useQueryGetExecInputIERecommendations();

  // execution
  const { submitJob, isSubmitExecutionJobLoading, errorSubmitExecutionJob } =
    useSubmitExecutionJobMutation();

  const {
    getExecutionImageRecommendations,
    isLoadingExecutionImageRecommendations,
    errrorExecutionImageRecommendations
  } = useQueryGetExecImageRecommendations();

  const {
    getExecutionInputRecommendations,
    isLoadingExecutionInputRecommendations,
    errrorExecutionInputRecommendations
  } = useQueryGetExecInputRecommendations();

  return {
    activityId,
    activityData,
    getFileVersions,
    getFileVersionsLoading,
    getFileVersionsError,
    executionImages,
    isLoadingGetExecutionImages,
    errorGetExecutionImages,
    submitJobFromIE,
    isSubmitExecutionJobIELoading,
    errorSubmitExecutionJobIE,
    getExecIEImageRecommendations,
    isLoadingExecIEImageRecommendations,
    errrorExecIEImageRecommendations,
    getExecInputIERecommendations,
    isLoadingExecInputIERecommendations,
    errrorExecInputIERecommendations,
    submitJob,
    isSubmitExecutionJobLoading,
    errorSubmitExecutionJob,
    getExecutionImageRecommendations,
    isLoadingExecutionImageRecommendations,
    errrorExecutionImageRecommendations,
    getExecutionInputRecommendations,
    isLoadingExecutionInputRecommendations,
    errrorExecutionInputRecommendations
  };
}
