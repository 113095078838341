import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import { CreateJob } from "../abstractComponents/createJob/createJob";
import { useQueryEnvironmentsQuery } from "../../backend/hooks/interactive/queryListEnvironments";
import { ApolloError } from "@apollo/client";
import { useQueryListInteractiveFilesQuery } from "../../backend/hooks/interactive/queryListInteractiveFiles";
import { BrowsePmxActivityContext } from "../../contexts/browsePmxActivityProvider";
import { castingFilesToRepObjectTree } from "../interactive/interactiveFileHelper";
import { useLazyGetJob } from "../../backend/hooks/execution/queryGetJob";
import { Environment } from "../../backend/interactiveTypes";

export function ExecutionNew() {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);
  const { treeHierarchyGlobal, refetchRepoObjects, isLoadingGetRepoObjects } =
    useContext(BrowsePmxActivityContext);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query: any = useQuery();
  const jobId = query.get("jobId");
  const { getJobs, job, isGetJobLoading, errorGetJob } = useLazyGetJob();
  const [executionFileSource, setExecutionFileSource] = useState<string>("");

  const { environments, isLoadingEnvironments, errorListEnvironments } =
    useQueryEnvironmentsQuery(activityId, false);

  // lazy query to retrieve on page load, to create new/clone job
  // This does not reload files on every modal click compared to IEEE bridge
  const {
    getListInteractiveFiles,
    interactiveFiles,
    isLoadingInteractiveFiles,
    errorLoadingInteractiveFiles
  } = useQueryListInteractiveFilesQuery();

  useEffect(() => {
    if (executionFileSource !== "phil" && executionFileSource !== "") {
      getListInteractiveFiles({
        variables: {
          environmentId: executionFileSource,
          activityId: activityId
        }
      });
    }
  }, [executionFileSource]);

  useEffect(() => {
    if (job.id && environments) {
      if (job.interactiveEnvironmentId) {
        const environmentExist = environments.find((env: Environment) => {
          return env.environmentId === job.interactiveEnvironmentId;
        });
        if (environmentExist) {
          setExecutionFileSource(environmentExist.environmentId);
        }
      } else if (job.id) {
        setExecutionFileSource("phil");
      }
    }
  }, [job, environments]);

  useEffect(() => {
    if (jobId) {
      getJobs({
        variables: {
          jobId: jobId
        }
      });
    }
    refetchRepoObjects();
  }, []);

  return (
    <>
      <NavBarPmxActivity activeIndex={2} />

      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          <Link to={`/activity/${activityId}/executions`}>Execution Jobs</Link>,
          <Typography>Create Job</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        <CreateJob
          executionFileSource={executionFileSource}
          setExecutionFileSource={setExecutionFileSource}
          interactiveEnvironments={environments}
          parentLoading={[
            isLoadingEnvironments,
            isLoadingInteractiveFiles,
            isLoadingGetRepoObjects,
            isGetJobLoading
          ]}
          parentError={
            [
              errorListEnvironments,
              errorLoadingInteractiveFiles,
              errorGetJob
            ] as ApolloError[]
          }
          repos={
            executionFileSource === "phil"
              ? treeHierarchyGlobal
              : castingFilesToRepObjectTree(interactiveFiles, activityData)
          }
          job={job}
        />
      </div>
    </>
  );
}
