import { MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import { Environment } from "../../../backend/interactiveTypes";
import { SelectChangeEvent } from "@mui/material/Select";

interface EnvironmentSelectProps {
  executionFileSource: string;
  setExecutionFileSource: (value: string) => void;
  environments: Environment[];
}
export function EnvironmentSelect(props: EnvironmentSelectProps) {
  return (
    <div className="mt-4">
      <FormControl fullWidth>
        <InputLabel>Environment</InputLabel>
        <Select
          id="job-environment-option"
          value={props.executionFileSource}
          label="Environment"
          onChange={(event) =>
            props.setExecutionFileSource(event.target.value as string)
          }
        >
          <MenuItem value="phil">PHIL File System</MenuItem>
          {props.environments?.map((environment: Environment) => (
            <MenuItem
              key={environment.environmentId}
              value={environment.environmentId}
            >
              {environment.environmentName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
