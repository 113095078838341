import { gql, useLazyQuery } from "@apollo/client";
export const ExecutionImageRecommendations = gql`
  query getImageRecommendations(
    $activityId: ID!
    $entrypoint: RepoObjectInput!
  ) {
    getImageRecommendations(
      input: { activityId: $activityId, entrypoint: $entrypoint }
    ) {
      images {
        id
        name
      }
    }
  }
`;

export function useQueryGetExecImageRecommendations() {
  const [
    getExecutionImageRecommendations,
    {
      data: executionImageRecommendations,
      loading: isLoadingExecutionImageRecommendations,
      error: errrorExecutionImageRecommendations
    }
  ] = useLazyQuery(ExecutionImageRecommendations, {
    fetchPolicy: "no-cache",
    context: { clientName: "execution" }
  });
  return {
    getExecutionImageRecommendations,
    execImageRecommendations: executionImageRecommendations?.images,
    isLoadingExecutionImageRecommendations,
    errrorExecutionImageRecommendations
  };
}
