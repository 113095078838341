import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import { CreateJob } from "../abstractComponents/createJob/createJob";
import { useQueryEnvironmentsQuery } from "../../backend/hooks/interactive/queryListEnvironments";
import { useQueryListInteractiveFilesQuery } from "../../backend/hooks/interactive/queryListInteractiveFiles";
import { ApolloError } from "@apollo/client/errors";
import { castingFilesToRepObjectTree } from "./interactiveFileHelper";
export function InteractiveCreateJob() {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);
  const environmentId = (useParams() as { environmentId: string })
    .environmentId;

  const { environments, isLoadingEnvironments, errorListEnvironments } =
    useQueryEnvironmentsQuery(activityId, false, environmentId);
  // lazy query to retrieve the list of interactive files every time the user clicks to open modal
  // TODO: look a way to clean up base on how the user interacts with IEE Bridge and creating new job
  // through the execution
  const {
    getListInteractiveFiles,
    interactiveFiles,
    isLoadingInteractiveFiles,
    errorLoadingInteractiveFiles
  } = useQueryListInteractiveFilesQuery();

  return (
    <>
      <NavBarPmxActivity activeIndex={1} />

      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          <Link to={`/activity/${activityId}/interactive`}>
            Interactive Environments
          </Link>
          <Link to={`/activity/${activityId}/interactive/${environmentId}`}>
            {environments && environments.length > 0
              ? environments[0].environmentName
              : ""}
          </Link>
          <Typography>Create Execution Job</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        <CreateJob
          parentLoading={[isLoadingInteractiveFiles, isLoadingEnvironments]}
          parentError={
            [
              errorLoadingInteractiveFiles,
              errorListEnvironments
            ] as ApolloError[]
          }
          executionFileSource={environmentId}
          repos={castingFilesToRepObjectTree(interactiveFiles, activityData)}
          getFilesForSelection={getListInteractiveFiles}
          IEEEBridge={true}
        />
      </div>
    </>
  );
}
