import { gql, useLazyQuery } from "@apollo/client";
export const ExecutionInputRecommendations = gql`
  query getExecutionInputRecommendations(
    $environmentId: ID!
    $repoName: String!
    $path: String!
  ) {
    getExecutionInputRecommendations(
      input: { environmentId: $environmentId, repoName: $repoName, path: $path }
    ) {
      inputs {
        repoName
        path
      }
    }
  }
`;

export function useQueryGetExecInputIERecommendations() {
  const [
    getExecInputIERecommendations,
    {
      data: executionInputRecommendations,
      loading: isLoadingExecInputIERecommendations,
      error: errrorExecInputIERecommendations
    }
  ] = useLazyQuery(ExecutionInputRecommendations, {
    fetchPolicy: "no-cache",
    context: { clientName: "interactive" }
  });
  return {
    getExecInputIERecommendations,
    execInputIRRecommendations: executionInputRecommendations?.inputs,
    isLoadingExecInputIERecommendations,
    errrorExecInputIERecommendations
  };
}
